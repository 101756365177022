.header{
    width: 100%;
    height: 56px;
    background: #00cc66;
}
.subHeader{
    max-width: 1100px;
    width: 100%;
    height: 100%;
    display: flex;
    margin: 0 auto;   
    align-items: center;
}

.logo{
    width: 137px;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}
.logo .logoimage{
    width: 75px;
}
.exploreText{ 
    font-size: 10px;
    font-style: italic; 
}
.plusText{ 
    font-size: 10px; 
    margin: 0 2px; 
    text-transform: capitalize;
    font-style: italic;
    color: yellow;
  }
.logo .goldenStar{
    width: 10px;
    height: 10px;
}

.searchInputContainer {
    width: 560px;
    position: relative;
    background: #fff;
    height: 36px;
    border-radius: 1px;
    overflow: hidden;
    display: flex;
  }
.searchInput {
    width: 518px;
    border: 0;
    height: 36px;
    outline: none;
    box-sizing: border-box;
    padding: 0 10px;
  }
.searchIconContainer {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
  }
  .rightMenu{
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
  }
.loginButton {
    display: block;
    width: 100px;
    height: 30px;
    background: #fff;
    text-align: center;
    line-height: 30px;
    color: #00cc66;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
  }
.more, .fullName {
    font-weight: bold;
    color: #fff;
    display: flex;
    align-items: center;
    margin: 0 20px;
    cursor: pointer;
  }
.cart {
    font-weight: bold;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 20px
  }

.authContainer{
  color: #212121;
  margin: 0 auto;
  border-radius: 2px;
  height: 528px;
  max-width: 750px;
  min-width: 650px;
}
.row{
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  height: 528px;
    max-width: 750px;
    min-width: 650px;
}

.leftspace{
  width: 40%;
  height: 100%;
  color: #fff;
  letter-spacing: 1px;
  background-image: url('../../images/login-image.png');
  background-color: #00cc66;
  background-repeat: no-repeat;
  background-position: center 85%;
  padding: 40px 33px;
  box-sizing: border-box;
}

.rightspace{
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
}

.loginInputContainer{
  width: 100%;
  box-sizing: border-box;
  padding: 0 30px;
  margin: 40px 0;
}