.delAdrContainer{
    display: flex;
    box-sizing: border-box;
}
.delTitle{
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 10px;
}
.delName{
    font-size: 12px;
    font-weight: 500;
}
.delAddress{
    font-size: 12px;
    margin: 10px 0;
}
.delPhoneNumber{
    font-size: 12px;
    font-weight: 500;
}
.delAdrDetails{
    padding: 20px;
    border-right: 1px solid #eee;
}
.delMoreActionContainer{
    padding: 20px;
}

.delItemImgContainer{
    width: 75px;
    height: 75px;
    overflow: hidden;
    text-align: center;
}
.delItemImgContainer > img{
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.orderTrack{
    width: 400px;
    display: flex;
}
.orderStatus{
    width: 33.33%;
    height: 3px;
    background: #777;
    position: relative;
    font-size: 12px;
}
.orderStatus.active{
    background: #26A541;
}
.orderStatus:last-child{
    width: 0;
}
.orderStatus .point{
    width: 10px;
    height: 10px;
    position: absolute;
    border-radius: 5px;
    background-color: #777;
    top: -3px;
}
.orderStatus .point.active{
    background-color: #26A541;
}
.orderInfo{
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    top: -26px;
    transform: translateX(-50%);
}

.delItemName{
    font-size: 12px;
}

.orderInfo .status{
    color: #26A541;
    text-transform: capitalize;
    font-weight: bold;
}

.orderInfo .date{
    font-size: 10px;
}