@font-face {
    font-family: "NotoSansLao-Medium";
    src: url("./assets/fonts/NotoSansLao-Medium.ttf");
  }
  body {
    font-family: "NotoSansLao-Medium", Ubuntu, "Saysettha MX", Helvetica, Arial,
    sans-serif;
    background: #f1f3f6;
  }
.flexRow{
    display: flex;
}
.sb{
    justify-content: space-between;
}
.onTopMenu{
  height: 65px;
  padding-top: 5px;
  text-align: end;
  padding-right: 20px;
}
.enLanguage{
  height: 60px;
  width: 200px;
  font-family: "NotoSansLao-Medium", Ubuntu, "Saysettha MX", Helvetica, Arial,
  sans-serif;
  font-size: 32px;
}
.laLanguage{
  height: 60px;
  width: 200px;
  font-family: "NotoSansLao-Medium", Ubuntu, "Saysettha MX", Helvetica, Arial,
  sans-serif;
  font-size: 32px;
}
.hmLanguage{
  height: 60px;
  width: 200px;
  font-family: "NotoSansLao-Medium", Ubuntu, "Saysettha MX", Helvetica, Arial,
  sans-serif;
  font-size: 32px;
}